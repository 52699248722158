import SubHeader from "../components/sub-header"
import React from "react"

const Resume = ({ location }) => {
  return (
    <React.Fragment>
      <SubHeader />
      <div className="aboutContainer">
        <h1>Coming soon</h1>
      </div>
    </React.Fragment>
  )
}

export default Resume
